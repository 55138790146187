import { useState } from 'react';
import  Home from '../pages/Home';

function usePageState() {
    const [currentPage, setPage] = useState({
        name: "Home",
        page: <Home />
    })

    return {currentPage, setPage};
}

export {usePageState}