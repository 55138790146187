const SchedulePage = () => {
  const schedule = [
    { time: '14h', event: 'Cerimônia de abertura' },
    { time: '14h15', event: '1ª Sessão' },
    { time: '15h50', event: 'Coffe-Break' },
    { time: '16h10', event: '2ª Sessão' },
    { time: '16h50', event: 'Término' },
  ];

  const team = [
    { role: 'Diretora Geral', name: 'Bruna Silva Borges' },
    { role: 'Tecnólogo da Informação', name: 'Guilherme Augusto Bisinoto Buttarello' },
    { role: 'Recepcionista', name: 'João Gabriel da Silva Sanches' },
    { role: 'Cenógrafa', name: 'Luana Arantes Amaral' },
    { role: 'Diretora de Imagem', name: 'Maria Luiza Santos Carlos' },
    { role: 'Diretor de áudio', name: 'Rafael Barbosa Miguel' },
    { role: 'Tesoureira', name: 'Maria Eduarda Rocha de Assis' },
    { role: 'Professor(a) Orientador(a)', name: 'Jaqueline Dall Agnol' }
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="max-w-4xl w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Equipe de Logística */}
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Equipe de Logística</h2>
          {team.map((member, index) => (
            <div key={index} className="my-4 text-center">
              <div className="text-lg font-medium text-gray-700">{member.role}</div>
              <div className="text-lg text-gray-600">{member.name}</div>
            </div>
          ))}
        </div>

        {/* Cronograma */}
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Cronograma</h2>
          {schedule.map((item, index) => (
            <div key={index} className="my-4 text-center">
              <div className="text-xl font-medium text-gray-700">{item.time}</div>
              <div className="text-lg text-gray-600">{item.event}</div>
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};

export default SchedulePage;
