import { Navbar } from './components/Navbar';
import { usePageState } from './states/pageState';
import LOGO_SESI from './sesi.svg';

function App() {

  const { currentPage, setPage } = usePageState();

  return (
    <>
      <div className="flex justify-center">
        <img src={LOGO_SESI} alt="logo SESI" width={"400px"} />
      </div>
      <Navbar setPage={setPage} currentPage={currentPage.name} />
      {currentPage.page}
    </>
  )
}

export default App
