import FAQ from "./FAQ";
import SchedulePage from "./Schedule";
import ONU from "../onu.png";

const ThemeSection = () => {
  return (
    <div className="bg-[#f7f5f3] py-12 px-6">
      <div className="max-w-4xl mx-auto space-y-6">
        <h1 className="text-3xl font-bold text-gray-800 text-center">Tema da simulação de 2024</h1>
        
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">A questão do Curdistão</h2>
          <p className="text-gray-700 leading-relaxed">
            A questão do Curdistão envolve o povo curdo e sua busca por autonomia ou independência em uma região que abrange partes da Turquia, Irã, Iraque e Síria. Os curdos são o maior grupo étnico do mundo sem um Estado independente. A luta por um Curdistão autônomo ou independente tem gerado tensões políticas, conflitos armados e repressões ao longo de muitas décadas, particularmente nos países onde vivem, como a insurgência do PKK na Turquia e os esforços curdos na Síria para estabelecer formas de autogoverno.
          </p>
          <p className="text-gray-700 leading-relaxed mt-4">
            Essa questão é multifacetada e apresenta uma oportunidade rica para debates, especialmente em temas relacionados a direitos humanos, autodeterminação, integridade territorial e segurança regional, como também tratar de propostas de mediação diplomática para resolver pacificamente os conflitos entre os governos e movimentos curdos. Além disso, a proteção dos direitos dos curdos e na facilitação de possíveis negociações sobre a autonomia regional ou acordos de paz seria um tema central.
          </p>
          <p className="text-gray-700 leading-relaxed mt-4">
            Em resumo, a questão do Curdistão oferece uma gama de tópicos para debate em uma simulação de conferência da ONU, desde autodeterminação até direitos humanos, segurança e possíveis soluções diplomáticas.
          </p>
        </div>
      </div>
    </div>
  );
};

function Home() {
  return <main className="space-y-10 my-10 max-w-4xl mx-auto">
    {/* Seção 1: O Evento */}
    <div className="space-y-3">
      <h1 className="text-3xl text-gray-600 font-bold">O evento</h1>
      <p className="text-gray-600">
        Será uma tarde que proporcionará muito mais do que crescimento acadêmico, pois oferecerá aos participantes uma nova perspectiva sobre a sociedade e a realidade global dos países representados.
      </p>
      <p className="text-gray-600">
        Debate, diplomacia e cooperação. Esses são os pilares da imersão única que você experimentará na simulação da conferência da ONU. Em cada edição, nossos alunos se transformam em diplomatas, jornalistas, juízes, advogados e promotores.
      </p>
      <p className="text-gray-600">
        A simulação fará com que você se sinta um verdadeiro cidadão global, proporcionando uma oportunidade única de aprender e desenvolver uma visão crítica sobre questões importantes, além de inspirar ideias que podem transformar o mundo em que vivemos.
      </p>
    </div>

    {/* Imagem */}
    <div className="flex justify-center">
      <img src={ONU} alt="logo SESI" className="w-4/5 max-w-lg" />
    </div>

    {/* Seção 2: Carta aos Convidados */}
    <div className="space-y-3">
      <h1 className="text-3xl text-gray-600 font-bold">Carta aos convidados</h1>
      <p className="text-gray-600 font-bold">Caros convidados,</p>
      <p className="text-gray-600">
        Nós, alunos do terceiro ano 2024, agradecemos antecipadamente pela sua participação conosco. Houve muita dedicação na organização deste importante evento, e podemos assegurar que esperamos transmitir a essência da nossa simulação. Desejamos que essa tarde seja memorável e enriquecedora para todos os envolvidos.
      </p>
      <p className="text-gray-600">
        O cenário diplomático atual é marcado por inúmeros conflitos nas esferas econômica, política, ambiental, entre outras. Dessa forma, o questionamento sobre temas do cotidiano e a busca por soluções viáveis para esses problemas se tornam fundamentais para a cidadania. Embora os conflitos e dificuldades de outros possam parecer distantes, eles nos afetam, tornando a necessidade de soluções eficazes mais urgente do que nunca. Compreender melhor o contexto em que vivemos resulta em maior inteligência e desenvolvimento do pensamento crítico, habilidades altamente valiosas.
      </p>
      <p className="text-gray-600">
        Portanto, nós, do terceiro ano, esperamos que a simulação da conferência da ONU seja um espaço para crescimento e troca de excelentes experiências. Aproveitem!
      </p>
      <p className="font-bold italic text-right">
        Atenciosamente,
        <br />
        Equipe Logística
      </p>
    </div>

    {/*  Botão Social */}
    <div className="flex justify-center">
      <a
        href="https://www.instagram.com/onu_sesi/profilecard/"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out"
      >
        Instagram
      </a>
    </div>

  <ThemeSection/>
    <SchedulePage />
    <FAQ />
  </main>
};

export default Home;