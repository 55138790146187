import { useState } from 'react';

const FAQItem = ({ question, answer }: {
  question: string
  answer: string
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-300 py-4">
      <button
        onClick={toggle}
        className="flex justify-between items-center w-full text-left"
      >
        <h3 className="text-lg font-medium text-gray-800">{question}</h3>
        <span className="text-xl">{isOpen ? '-' : '+'}</span>
      </button>

      <div
        className={`transition-max-height duration-300 ease-in-out overflow-hidden ${isOpen ? 'max-h-40' : 'max-h-0'
          }`}
      >
        <p className="mt-2 text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: 'O que é a simulação de conferência da ONU?',
      answer:
        'São eventos organizados para simular organismos internacionais e discutir tópicos da agenda global, promovendo a melhor compreensão da realidade através do debate e da interação entre diversas pessoas.',
    },
    {
      question: 'Por que eu tenho que pagar para assistir a simulação?',
      answer:
        'Esse é um evento realizado pelos alunos do terceiro ano que, além de organizarem a reunião, oferecem coffee break. Assim, a taxa para assistir contribui para realizar essas atividades e, também, para cobrir os demais serviços de infraestrutura.',
    },
    {
      question: 'Em caso de desistência, receberei meu dinheiro de volta?',
      answer:
        'Não. A logística planeja o evento com antecedência; portanto, o dinheiro ingressos será utilizado para custear itens que lhe serão providos.',
    },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">Dúvidas frequentes</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
