import Home from "../pages/Home";

function Navbar({ setPage, currentPage }: {
    currentPage: string
    setPage:  React.Dispatch<any>
}) { 
    const ButtonNav = ({ page, children }: {
        page: JSX.Element;
        children: string
    }) => {
        const activeButton = currentPage === children ? "text-gray-900 border-b-2 border-gray-900" : "text-gray-600 hover:text-gray-900"

        return (
            <button className={`font-bold py-2 px-4 ${activeButton}`} onClick={() => {
                setPage({
                    name: children,
                    page: page
                });
                console.log(children);
            }}>
                {children}
            </button>
        );
    }

    return (
        <div className="flex justify-center py-2 border-y space-x-3">
            <ButtonNav page={<Home />}>Home</ButtonNav>
        </div>
    );
}

export { Navbar };
